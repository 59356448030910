<template>

   <section class='product'>
        
        <div class='product__grid grid'>
            <div class='product__grid__col product__grid__col--info grid__col'>
                <div class='product__info'>
                    <router-link class='product__info__back' :to='{name: "home"}' tabindex='0'>
                        <SvgIconCaretDown />
                        Back
                    </router-link>

                    <h1 class='product__info__title'>
                        <span v-for='i in 2' class='product__info__title__text' :class='`product__info__title__text--${i}`'>
                            <span v-for='(word, j) in productTitleArray(product.title)' :key='`word-${j}`' v-text='word' />
                        </span>
                    </h1>

                    <div class='product__info__price'>
                        <div v-for='i in 2' class='product__info__price__text' :class='`product__info__price__text--${i}`'>
                            <template v-if='isFree'>
                                <span class='product__info__price__free' v-text='"Free"' />
                            </template>
                            <template v-else>
                                <span class='product__info__price__sign' v-text='"$"' />
                                <span class='product__info__price__dollars' v-text='price.dollars' />
                                <span class='product__info__price__dot' v-text='"."' />
                                <span class='product__info__price__cents' v-text='price.cents' />
                            </template>
                        </div>
                    </div>

                    <div class='product__info__description' v-html='widont(product.description)' />

                    <div class='product__info__linked' v-if='linkedProducts?.length > 0'>
                        <router-link
                            class='product__info__linked__product'
                            :class='{
                                "product__info__linked__product--is-current": linkedProduct.product.handle === product.handle
                            }'
                            v-for='linkedProduct in linkedProducts'
                            :key='linkedProduct.product.id'
                            :to='{name: "product", params: {slug: linkedProduct.product.handle}}'>

                            <div class='product__info__linked__product__thumb'>
                                <Media :media='linkedProduct.thumb' :isFullbleed='true' />
                            </div>
                            <div class='product__info__linked__product__title' v-text='linkedProductTitle(linkedProduct)' />
                        </router-link>
                    </div>

                    <!-- variant selector -->
                    <div v-if='product.variants.length > 1' class='product__info__variants'>
                        <button
                            v-for='(variant) in product.variants'
                            :key='variant.id'
                            class='product__info__variants__variant'
                            :class='{
                                "state-selected": variant.id === activeVariant.id,
                                "state-disabled": !variant.available
                            }'
                            @click='activeVariant = variant'>

                            <span v-text='variant.public_title || variant.title' />
                        </button>
                    </div>

                    <!-- waitlist form + cta -->
                    <template v-if='isWaitlist'>
                        <form @submit.prevent='onWaitlistFormSubmit' v-if='!waitlist?.isSubmitted'>
                            <div class='product__info__input'>
                                <input class='product__info__input__field'
                                    :class='{
                                        "product__info__input__field--is-submit-attemped": !waitlist?.isSubmitAttempted
                                    }'
                                    ref='waitlistEmailInput'
                                    type='email'
                                    :placeholder='klaviyo.inputPlaceholder'
                                    tabindex='0'
                                    @keyup='onWaitlistInputKeyup' 
                                />
                            </div>
                            <div class='product__info__cta'>
                                <a class='product__info__cta__cta cta cta--alt'
                                    :class='{
                                        "cta--disabled": !waitlist?.isValid
                                    }'
                                    v-text='waitlist?.ctaText || "Waitlist"'
                                    :aria-label='`Join the waitlist for ${product.title}`'
                                    role='button'
                                    tabindex='0'
                                    @click.prevent='onWaitlistSubmitClick'
                                />
                                
                                <div v-if='waitlist?.errorText' class='product__info__cta__error'>
                                    <p v-text='waitlist.errorText' />
                                </div>
                                <div v-else class='product__info__cta__legal'>
                                    <p>By submitting my email above, I agree to the <a href='https://a24films.com/terms-of-use' target='_blank' rel='noopener'>terms</a>.</p>
                                    <p>For more details, see the <a href='https://a24films.com/privacy-policy' target='_blank' rel='noopener'>Privacy Policy</a>.</p>
                                </div>
                            </div>
                        </form>
                        <template v-else-if='waitlist?.isSubmitting'>
                            <div class='product__info__forminfo'>
                                <Spinner class='product__info__forminfo__spinner' :variants='["large"]' />
                            </div>
                        </template>
                        <template v-else-if='waitlist?.isSubmitted'>
                            <div class='product__info__forminfo'>
                                <p class='product__info__forminfo__title' v-html='waitlist?.successHead' />
                                <p class='product__info__forminfo__description' v-html='waitlist?.successText' />
                            </div>
                        </template>
                    </template>

                    <!-- downloadable product form + cta -->
                    <template v-else-if='isDownloadable'>
                        <form @submit.prevent='onDownloadableFormSubmit' v-if='!downloadable?.isSubmitted'>
                            <div class='product__info__input'>
                                <input class='product__info__input__field'
                                    :class='{
                                        "product__info__input__field--is-submit-attemped": !downloadable?.isSubmitAttempted
                                    }'
                                    ref='downloadableEmailInput'
                                    type='email'
                                    :placeholder='klaviyo.inputPlaceholder'
                                    tabindex='0'
                                    @keyup='onDownloadInputKeyup' 
                                />
                            </div>
                            <div class='product__info__cta'>
                                <a class='product__info__cta__cta cta cta--alt'
                                    :class='{
                                        "cta--disabled": !downloadable?.isValid
                                    }'
                                    v-text='downloadable?.ctaText || "Download"'
                                    :aria-label='`Get download link for ${product.title}`'
                                    role='button'
                                    tabindex='0'
                                    @click.prevent='onDownloadSubmitClick'
                                />
                                
                                <div v-if='downloadable?.errorText' class='product__info__cta__error'>
                                    <p v-text='downloadable.errorText' />
                                </div>
                                <div v-else class='product__info__cta__legal'>
                                    <p>By clicking "Download," I agree to the <a href='https://a24films.com/terms-of-use' target='_blank' rel='noopener'>terms</a>.</p>
                                    <p>For more details, see the <a href='https://a24films.com/privacy-policy' target='_blank' rel='noopener'>Privacy Policy</a>.</p>
                                </div>
                            </div>
                        </form>
                        <template v-else-if='downloadable?.isSubmitting'>
                            <div class='product__info__forminfo'>
                                <Spinner class='product__info__forminfo__spinner' :variants='["large"]' />
                            </div>
                        </template>
                        <template v-else-if='downloadable?.isSubmitted'>
                            <div class='product__info__forminfo'>
                                <p class='product__info__forminfo__title' v-html='downloadable?.successHead' />
                                <p class='product__info__forminfo__description' v-html='downloadable?.successText' />
                            </div>
                        </template>
                    </template>

                    <!-- normal product cta -->
                    <template v-else>
                        <div class='product__info__cta'>
                            <a class='cta cta--alt'
                                :class='{
                                    "cta--disabled": !activeVariant.available
                                }' 
                                :aria-label='`Add ${product.title} to cart for $${price.dollars}.${price.cents}`'
                                role='button'
                                tabindex='0'
                                @click.prevent='onAddToCartClick'>
                                
                                <template v-if='activeVariant.available'>
                                    <span v-text='ctaText' />
                                </template>
                                <template v-else>
                                    <span>Sold Out</span>
                                </template>
                            </a>
                        </div>
                    </template>

                    <!-- size chart -->
                    <div v-if='sizeChartInnerHtml' class='product__info__sizechart'>
                        <div class='product__info__sizechart__text product__info__sizechart__text--above'>
                            <h6 class='product__info__sizechart__heading'>Sizing Guide</h6>
                            <span v-if='sizeChart.text_above_chart' v-text='": " + sizeChart.text_above_chart' />
                        </div>
                        <div class='product__info__sizechart__table-wrapper'>
                            <table class='product__info__sizechart__table' v-html='sizeChartInnerHtml' />
                        </div>
                        
                        <p v-if='sizeChart.text_below_chart' class='product__info__sizechart__text product__info__sizechart__text--below' v-text='sizeChart.text_below_chart' />
                    </div>

                    <!-- product features -->
                    <div v-if='features' class='product__info__features'>
                        <ul class='product__info__features__list'>
                            <template v-for='(feature, i) in features' :key='`feature-${i}`'>
                                <li class='product__info__features__item'>
                                    <div class='product__info__features__item__title' v-text='feature.title' />
                                    <div class='product__info__features__item__description' v-text='feature.description' />
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>


            <div class='product__grid__col product__grid__col--media grid__col'>
                <div class='product__media'>
                    <Swiper
                        class='product__media__swiper'
                        @afterInit='onSwiperInit'
                        :lazyPreloadPrevNext='2'
                        :slidesPerView='1'
                        :rewind='true'
                        :effect='"creative"'
                        :creativeEffect='{
                            prev: {
                                shadow: true,
                                translate: [0, 0, -400],
                            },
                            next: {
                                translate: ["100%", 0, 0],
                            },
                        }'
                        :pagination='{
                            clickable: true
                        }'
                        :modules='swiperModules'
                        :observer='true'>

                        <template v-for='(media, i) in product.media' :key='`media-${i}`'>
                            <SwiperSlide>
                                <Media :media='media' :isFullbleed='true' />
                            </SwiperSlide>
                        </template>
                    </Swiper>
                </div>
            </div>
        </div>
   </section>

</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination, A11y, EffectCreative } from 'swiper'
import { mapActions } from 'pinia'
import { useCartStore } from '^/stores/cart'
import { widont, productTitleArray } from '^/utils/typography'
import Spinner from '^/components/elements/Spinner.vue'
import Media from '^/components/elements/Media.vue'
import SvgIconCaretDown from '^/components/svg/SvgIconCaretDown.vue'

const klaviyo = window.app.klaviyo || {}

export default {
    name: 'ProductTier',
    props: {
        product: {
            type: Object,
            required: true
        },
        features: {
            type: Array,
            default: []
        },
        linkedProducts: {
            type: Array,
            default: []
        },
        ctaTextOverride: {
            type: String,
            default: true
        },
        waitlistId: {
            type: String,
            default: ''
        },
        sizeChart: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        activeVariant: null,

        klaviyo: {
            apiKey: klaviyo?.apiKey || 'Uk6tXe', // staging: 'YkpeMF'
            inputPlaceholder: klaviyo?.inputPlaceholder || 'Enter your email...',
            genericError: klaviyo?.genericError || 'There was a problem submitting your email.',
        },
        newsletter: {
            listId: klaviyo?.newsletter?.listId || 'VeaC7G', // staging: 'YzxRwE'
        },
        downloadable: {
            isValid: false,
            isSubmitAttempted: false,
            isSubmitting: false,
            isSubmitted: false,
            errorText: '',
            ctaText: klaviyo?.downloadable?.ctaText || 'Download',
            successHead: klaviyo?.downloadable?.successHead || 'Thanks!',
            successText: klaviyo?.downloadable?.successText || 'Check your email for a download link.'
        },
        waitlist: {
            isValid: false,
            isSubmitAttempted: false,
            isSubmitting: false,
            isSubmitted: false,
            errorText: '',
            ctaText: klaviyo?.waitlist?.ctaText || 'Waitlist',
            successHead: klaviyo?.waitlist?.successHead || 'Thanks!',
            successText: klaviyo?.waitlist?.successText || '"We\'ll let you know if this product becomes available.'
        },

        swiper: null,
        swiperModules: [Navigation, Pagination, A11y, EffectCreative]
    }),
    computed: {
        isWaitlist () {
            return !!this.waitlistId && !this.activeVariant?.available
        },
        isDownloadable () {
            return this.product?.type === 'Downloadable'
        },
        isFree () {
            return this.activeVariant?.price === 0
        },
        price () {
            const price = this.activeVariant?.price || 0
            return {
                dollars: Math.floor(price / 100),
                cents: price % 100 || '00'
            }
        },
        ctaText () {
            return this.ctaTextOverride || 'Add to Cart'
        },
        sizeChartInnerHtml () {
            const sizeData = this.sizeChart?.tops
            if (!sizeData || sizeData.length < 1) return ''

            // properties for the rows, excluding 'name' and 'size'
            const properties = Object.keys(sizeData[0]).filter(key => !['name', 'size'].includes(key))

            // start the table and add size headers
            let html = '<thead><tr><th></th>'
            sizeData.forEach(item => { html += `<th>${item.size}</th>` })
            html += '</tr></thead><tbody>'

            // add rows for each property
            properties.forEach(prop => {
                html += `<tr><td class='product__info__sizechart__table__rowlabel'>${prop.replace('_', '<br>')}</td>`
                sizeData.forEach(item => { html += `<td>${item[prop]}</td>` })
                html += '</tr>'
            })

            html += '</tbody>'

            return html
        }
    },
    created() {
        this.activeVariant = this.product?.variants[0]
    },
    methods: {
        ...mapActions(useCartStore, [
            'addToCart'
        ]),
        widont (text) {
            return widont(text, " ")
        },
        linkedProductTitle (product) {
            if (!this.linkedProducts || !product.product.title) return ''
            const linkedProds = this.linkedProducts

            let productTitle = product.product.title

            let firstTitleWords = linkedProds[0].product.title.split(' ')
            let firstWord = firstTitleWords[0]
            let secondWord = firstTitleWords[1]
            let penultimateWord = firstTitleWords[firstTitleWords.length - 2]
            let lastWord = firstTitleWords[firstTitleWords.length - 1]

            const isAllSameFirstWord = linkedProds.every(obj => obj.product.title.startsWith(firstWord))
            const isAllSameSecondWord = linkedProds.every(obj => obj.product.title.startsWith(firstWord + ' ' + secondWord))

            const isAllSameLastWord = linkedProds.every(obj => obj.product.title.endsWith(lastWord))
            const isAllSamePenultimateWord = linkedProds.every(obj => obj.product.title.endsWith(penultimateWord + ' ' + lastWord))

            
            if (isAllSameSecondWord) {
                productTitle = productTitle.replace(firstWord + ' ' + secondWord, '').trim()
            } else if (isAllSameFirstWord) {
                productTitle = productTitle.replace(firstWord, '').trim()
            }

            if (isAllSamePenultimateWord) {
                productTitle = productTitle.replace(new RegExp(penultimateWord + ' ' + lastWord + '$'), '').trim()
            } else if (isAllSameLastWord) {
                productTitle = productTitle.replace(new RegExp(lastWord + '$'), '').trim()
            }
            
            return productTitle
        },
        onSwiperInit (swiper) {
            this.swiper = swiper
        },
        onAddToCartClick () {
            this.addToCart(this.activeVariant, 1)
        },

        // waitlist
        onWaitlistFormSubmit () {
            this.submitWaitlistRequest()
        },
        onWaitlistInputKeyup (e) {
            this.waitlist.isValid = e.target?.validity?.valid
        },
        onWaitlistSubmitClick () {
            this.submitWaitlistRequest()
        },
        submitWaitlistRequest () {
            this.waitlist.isSubmitAttempted = true
            this.waitlist.isSubmitting = true

            const email = this.$refs.waitlistEmailInput?.value || ''
            const headers = {
                'accept': 'application/json',
                'revision': '2025-01-15',
                'content-type': 'application/json'
            }
            const klaviyoProfile = {
                'data': {
                    'type': 'profile',
                    'attributes': {
                        'email': email
                    }
                }
            }

            fetch('https://a.klaviyo.com/client/subscriptions/?company_id=' + this.klaviyo.apiKey, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    'data': {
                        'type': 'subscription',
                        'attributes': {
                            'profile': klaviyoProfile
                        },
                        'relationships': {
                            'list': {
                                'data': {
                                    'type': 'list',
                                    'id': this.waitlistId
                                }
                            }
                        }
                    }
                })
            }).then(async response => {
                if (!response.ok) {
                    const data = await response.json()
                    this.waitlist.errorText = data?.errors[0]?.detail || this.klaviyo.genericError
                    this.waitlist.isSubmitting = false
                    return
                }

                this.waitlist.isSubmitting = false
                this.waitlist.isSubmitted = true
            }).catch(() => {
                this.waitlist.errorText = this.klaviyo.genericError
            })
        },

        // downloadable
        onDownloadableFormSubmit () {
            this.submitDownloadableRequest()
        },
        onDownloadInputKeyup (e) {
            this.downloadable.isValid = e.target?.validity?.valid
        },
        onDownloadSubmitClick () {
            this.submitDownloadableRequest()
        },
        submitDownloadableRequest () {
            this.downloadable.isSubmitAttempted = true
            this.downloadable.isSubmitting = true

            const email = this.$refs.downloadableEmailInput?.value || ''
            const headers = {
                'accept': 'application/json',
                'revision': '2025-01-15',
                'content-type': 'application/json'
            }
            const klaviyoProfile = {
                'data': {
                    'type': 'profile',
                    'attributes': {
                        'email': email
                    }
                }
            }

            // trigger "Hazbin Downloadable Request" event
            fetch('https://a.klaviyo.com/client/events/?company_id=' + this.klaviyo.apiKey, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    'data': {
                        'type': 'event',
                        'attributes': {
                            'properties': {
                                'email': email,
                                'product_id': this.product?.id,
                                'source': 'Hazbin Hotel Shop'
                            },
                            'metric': {
                                'data': {
                                    'type': 'metric',
                                    'attributes': {
                                        'name': 'Hazbin Downloadable Request'
                                    }
                                }
                            },
                            'profile': klaviyoProfile
                        }
                    }
                })
            }).then(async response => {
                if (!response.ok) {
                    const data = await response.json()
                    this.downloadable.errorText = data?.errors[0]?.detail || this.klaviyo.genericError
                    this.downloadable.isSubmitting = false
                    return
                }

                this.downloadable.isSubmitting = false
                this.downloadable.isSubmitted = true

                fetch('https://a.klaviyo.com/client/subscriptions/?company_id=' + this.klaviyo.apiKey, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({
                        'data': {
                            'type': 'subscription',
                            'attributes': {
                                'profile': klaviyoProfile
                            },
                            'relationships': {
                                'list': {
                                    'data': {
                                        'type': 'list',
                                        'id': this.newsletter.listId
                                    }
                                }
                            }
                        }
                    })
                })

            }).catch(() => {
                this.downloadable.errorText = this.klaviyo.genericError
            })
        },

        productTitleArray: productTitleArray
    },
    components: {
        Spinner,
        Media,
        Swiper,
        SwiperSlide,
        SvgIconCaretDown
    }
};
</script>